<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :height="30"
      :z-index="10000"
    ></loading>
    <div class="pull-right">
      <b-button
        :variant="
          item.id == $route.params.conditionId ? 'success' : 'secondary'
        "
        class="mt-1 ml-3"
        :to="{
          name: $route.name,
          params: {
            id: $route.params.id,
            loanTypeId: $route.params.loanTypeId,
            conditionId: item.id,
          },
        }"
      >
        <i class="fa fa-chevron-right"></i>
      </b-button>
    </div>
    <div class="pull-right">
      <b-form-checkbox value="true" v-model="isActive" switch></b-form-checkbox>
      <b-link @click="editback(item)">засах</b-link>
    </div>
    {{ item.loanMinAmount | currecry }} - {{ item.loanMaxAmount | currecry
    }}<br />
    <b>Хүү:</b> {{ item.interest }}% <b class="ml-3">Хугацаа:</b>
    {{ item.term }} сар <b class="ml-3">Олголтын шимтгэл:</b>
    {{ item.loanFeePercent }}%
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      isLoading: false,
      isActive: this.item.isActive,
    };
  },
  props: ["item", "editback", "callback"],
  watch: {
    isActive: {
      handler(_isActive) {
        this.changeIsActive(_isActive);
      },
      deep: true,
    },
  },
  methods: {
    changeIsActive: async function(_isActive) {
      this.$data.isLoading = true;
      var response = await this.$http.post(
        this.$config.API_URL + "PurchaseWebService/condition_is_active_change",
        {
          id: this.item.id,
          isActive: _isActive,
        },
        {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        }
      );

      this.callback();
      //console.log(response)
      this.$data.isLoading = false;
    },
  },
};
</script>
